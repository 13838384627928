import AccountBalanceWalletTwoToneIcon from "@mui/icons-material/AccountBalanceWalletTwoTone";
import AssignmentIndTwoToneIcon from "@mui/icons-material/AssignmentIndTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import PaymentTwoToneIcon from "@mui/icons-material/PaymentTwoTone";
import PaymentsTwoToneIcon from "@mui/icons-material/PaymentsTwoTone";
import ConfirmationNumberTwoToneIcon from "@mui/icons-material/ConfirmationNumberTwoTone";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import VerticalSplitTwoToneIcon from "@mui/icons-material/VerticalSplitTwoTone";
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import ReceiptTwoToneIcon from "@mui/icons-material/ReceiptTwoTone";
import SupervisorAccountTwoToneIcon from "@mui/icons-material/SupervisorAccountTwoTone";
import AutorenewTwoToneIcon from "@mui/icons-material/AutorenewTwoTone";
import BusinessTwoToneIcon from "@mui/icons-material/BusinessTwoTone";
import ArticleTwoToneIcon from "@mui/icons-material/ArticleTwoTone";
import AssignmentTwoToneIcon from "@mui/icons-material/AssignmentTwoTone";
import SwitchAccountTwoToneIcon from "@mui/icons-material/SwitchAccountTwoTone";
import SettingsEthernetTwoToneIcon from "@mui/icons-material/SettingsEthernetTwoTone";
import TollTwoToneIcon from "@mui/icons-material/TollTwoTone";
import ArrowCircleUpTwoToneIcon from "@mui/icons-material/ArrowCircleUpTwoTone";
import ArrowCircleDownTwoToneIcon from "@mui/icons-material/ArrowCircleDownTwoTone";
import MonetizationOnTwoToneIcon from "@mui/icons-material/MonetizationOnTwoTone";
import ShareTwoToneIcon from "@mui/icons-material/ShareTwoTone";
import PendingActionsTwoToneIcon from "@mui/icons-material/PendingActionsTwoTone";
import CorporateFareTwoToneIcon from "@mui/icons-material/CorporateFareTwoTone";
import AssessmentIcon from "@mui/icons-material/Assessment";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import {
  ContactsTwoTone,
  ManageAccountsTwoTone,
  ManageSearchTwoTone,
  PolicyTwoTone,
  SavingsTwoTone,
} from "@mui/icons-material";
import RequestQuoteTwoToneIcon from "@mui/icons-material/RequestQuoteTwoTone";

import {
  AccountTreeTwoTone,
  CampaignTwoTone,
  ReceiptLong,
  TimelapseTwoTone,
  TimelineTwoTone,
  TuneTwoTone,
  ViewAgendaTwoTone,
} from "@mui/icons-material";

const menuItems = [
  {
    heading: "Dashboard",
    items: [
      {
        name: "Home",
        user_allowed: [
          "admin",
          "support",
          "finops",
          "sales",
          "compliance",
          "invoice",
          "finance",
          "tos",
        ],
        icon: HomeTwoToneIcon,
        link: "/preview/dashboards/analytics",
      },

      {
        name: "Admin Accounts",
        user_allowed: ["admin"],
        icon: SupervisorAccountTwoToneIcon,
        link: "/preview/dashboards/all-accounts",
      },

      {
        name: "Internal Treasury",
        user_allowed: ["admin", "tos"],
        icon: SavingsTwoTone,
        items: [
          {
            name: "Balance At A Glance",
            link: "/preview/dashboards/internal-treasury",
          },
        ],
      },
      {
        name: "Organizations",
        user_allowed: ["admin", "sales", "compliance", "finance"],
        icon: BusinessTwoToneIcon,
        items: [
          {
            name: "All Organizations",
            link: "/preview/dashboards/organization",
          },
          {
            name: "Invite New Organization",
            link: "/preview/dashboards/invite-organization",
          },
        ],
      },
      {
        name: "Partner",
        user_allowed: ["admin", "finance"],
        icon: ContactsTwoTone,
        items: [
          {
            name: "All Partners",
            link: "/preview/dashboards/partner",
          },
          {
            name: "Commission Report",
            link: "/preview/dashboards/partner-commission-report",
          },
        ],
      },
      {
        name: "Pending Actions",
        user_allowed: ["admin", "compliance"],
        icon: PendingActionsTwoToneIcon,
        items: [
          {
            name: "Settlement Bank",
            link: "/preview/dashboards/pending-actions/settlement-bank",
          },
          {
            name: "Payment Method Setting",
            link: "/preview/dashboards/pending-actions/payment-method-setting",
          },
          {
            name: "Inbound Fund Notice",
            link: "/preview/dashboards/pending-actions/inbound-fund-notice",
          },
          {
            name: "Invest Account Activation",
            user_allowed: ["admin", "tos"],
            link: "/preview/dashboards/invest-account-activation",
          },
          {
            name: "Partner Org Feature Control",
            link:
              "/preview/dashboards/pending-actions/partner-org-feature-control",
          },
          // {
          //   name: "Payout Method",
          //   link: "/preview/dashboards/pending-actions/payout-method",
          // },
        ],
      },
      {
        name: "Compliance/AFCC",
        user_allowed: ["admin", "compliance"],
        icon: PolicyTwoTone,
        items: [
          {
            name: "Transaction Monitoring",
            link: "/preview/dashboards/transaction-monitoring/dashboard",
          },
          {
            name: "List Manager",
            link: "/preview/dashboards/list-manager/risky-words",
          },
          {
            name: "Daily Screening",
            link: "/preview/dashboards/daily-screening/alerts",
          },
          {
            name: "Customer Screening",
            link: "/preview/dashboards/customer-screening/alerts",
          },
          {
            name: "Suspended Transactions",
            link: "/preview/dashboards/suspended-transactions",
          },
        ],
      },
      {
        name: "Organization User",
        user_allowed: [
          "admin",
          "sales",
          "support",
          "finops",
          "compliance",
          "finance",
        ],
        icon: AssignmentIndTwoToneIcon,
        items: [
          {
            name: "Organization Specific Users",
            link: "/preview/dashboards/user/orgs",
          },
          {
            name: "Impersonate Org Users",
            link: "/preview/dashboards/user/impersonate",
          },
          {
            name: "Session IP Location",
            link: "/preview/dashboards/user/all-session-ip-locations",
          },
        ],
      },
      {
        name: "KYB",
        user_allowed: ["admin", "sales", "compliance", "finance", "support"],
        icon: VerticalSplitTwoToneIcon,
        items: [
          {
            name: "All Organizations",
            link: "/preview/dashboards/kyb/all-orgs",
          },
          {
            name: "All Applications",
            link: "/preview/dashboards/kyb",
          },
          {
            name: "New Applications",
            link: "/preview/dashboards/kyb/cust-submitted",
          },
          {
            name: "In Review",
            link: "/preview/dashboards/kyb/in-review",
          },
          {
            name: "Resubmitted",
            link: "/preview/dashboards/kyb/resubmitted",
          },
          {
            name: "Approved",
            link: "/preview/dashboards/kyb/approved",
          },
          {
            name: "Rejected",
            link: "/preview/dashboards/kyb/rejected",
          },
          {
            name: "To Be Resubmitted",
            link: "/preview/dashboards/kyb/to-be-resubmitted",
          },
          {
            name: "Not Started",
            link: "/preview/dashboards/kyb/not-started",
          },
        ],
      },
      {
        name: "Contract",
        user_allowed: ["admin", "sales", "compliance", "finance", "support"],
        icon: AssignmentTwoToneIcon,
        link: "/preview/dashboards/contract/orgs",
      },
      {
        name: "Org Documents",
        user_allowed: ["admin", "compliance", "support"],
        icon: ArticleTwoToneIcon,
        items: [
          {
            name: "All Organizations",
            link: "/preview/dashboards/documents/orgs",
          },
        ],
      },
      {
        name: "Connector",
        user_allowed: ["admin", "compliance", "support"],
        icon: SettingsEthernetTwoToneIcon,
        items: [
          {
            name: "New Connector",
            link: "/preview/dashboards/connector",
          },
          {
            name: "All Connectors",
            link: "/preview/dashboards/all-connectors",
          },
          {
            name: "Key Connector Map",
            link: "/preview/dashboards/connector/key-connector-map",
          },
        ],
      },
      {
        name: "Org Connector Config",
        user_allowed: ["admin", "compliance", "support"],
        icon: ViewAgendaTwoTone,
        items: [
          {
            name: "New Connector Config",
            link: "/preview/dashboards/new-org-connector-config",
          },
          {
            name: "All Connector Configs",
            link: "/preview/dashboards/all-org-connector-configs",
          },
          {
            name: "All Organizations",
            link: "/preview/dashboards/org-connector-config/orgs",
          },
        ],
      },
      {
        name: "Payin Method Config",
        user_allowed: ["admin", "compliance", "support"],
        icon: AccountTreeTwoTone,
        items: [
          {
            name: "New Payin Method Config",
            link: "/preview/dashboards/new-payin-method-config",
          },
          {
            name: "All Payin Method Configs",
            link: "/preview/dashboards/all-payin-method-configs",
          },
          {
            name: "All Organizations",
            link: "/preview/dashboards/payin-method-config/orgs",
          },
        ],
      },
      {
        name: "Payin Method",
        user_allowed: ["admin", "compliance", "support"],
        icon: ArrowCircleDownTwoToneIcon,
        items: [
          {
            name: "New Payin Method",
            link: "/preview/dashboards/payin-method",
          },
          {
            name: "All Payin Methods",
            link: "/preview/dashboards/all-payin-methods",
          },
        ],
      },
      {
        name: "Payout Method",
        user_allowed: ["admin", "compliance", "support"],
        icon: ArrowCircleUpTwoToneIcon,
        items: [
          {
            name: "New Payout Method",
            link: "/preview/dashboards/payout-method",
          },
          {
            name: "All Payout Methods",
            link: "/preview/dashboards/all-payout-methods",
          },
        ],
      },
      // {
      //   name: "Transaction Monitoring",
      //   icon: PaymentTwoToneIcon,
      //   items: [
      //     {
      //       name: "Add New Rule",
      //       link: "/preview/dashboards/transaction-rules/new-transaction-rule",
      //     },
      //     {
      //       name: "All Transaction Rules",
      //       link: "/preview/dashboards/transaction-rules/all-transaction-rules",
      //     },
      //   ],
      // },
      {
        name: "Pricing",
        user_allowed: ["admin", "compliance", "finance", "support"],
        icon: ReceiptTwoToneIcon,
        items: [
          {
            name: "Pricing Setup",
            items: [
              {
                name: "Create Pricing Setup",
                link: "/preview/dashboards/pricing/pricing-setup/create",
              },
              {
                name: "All Pricing Setup",
                link: "/preview/dashboards/pricing/pricing-setup",
              },
            ],
          },
          {
            name: "Plans",
            link: "/preview/dashboards/pricing/plans",
          },
          {
            name: "Plan Association",
            link: "/preview/dashboards/pricing/plan-association",
          },
          {
            name: "Country Plan - Default",
            link: "/preview/dashboards/pricing/country-plan",
          },
          {
            name: "Tax Config",
            items: [
              {
                name: "Country Tax",
                link: "/preview/dashboards/pricing/country-tax",
              },
              {
                name: "Organization Tax",
                link: "/preview/dashboards/pricing/organization-tax",
              },
            ],
          },
          {
            name: "Monthly Minimum",
            link: "/preview/dashboards/pricing/monthly-minimum",
          },
          {
            name: "Recurring Fees",
            link: "/preview/dashboards/pricing/recurring-fees",
          },
          {
            name: "Payin Method Pricing",
            link: "/preview/dashboards/pricing/payin-method-pricing",
          },
          {
            name: "Payout Method Pricing",
            link: "/preview/dashboards/pricing/payout-method-pricing",
          },
          {
            name: "Fx Conversion Markup",
            link: "/preview/dashboards/pricing/fx-conversion-markup",
          },
          {
            name: "Org Pricing - At a glance",
            link: "/preview/dashboards/pricing/organizations",
          },
          {
            name: "Cost Configuration",
            link: "/preview/dashboards/pricing/cost-config",
          },
        ],
      },
      {
        name: "Invoice",
        user_allowed: [
          "admin",
          "support",
          "finops",
          "compliance",
          "invoice",
          "finance",
        ],
        icon: DescriptionTwoToneIcon,
        items: [
          {
            name: "Invoice Dashboard",
            link: "/preview/dashboards/invoice/dashboard",
          },
          {
            name: "Invoice at a Glance",
            link: "/preview/dashboards/invoice/overview",
          },
          {
            name: "Invoice Org Config",
            link: "/preview/dashboards/invoice/invoice-config",
          },
          {
            name: "All Organizations",
            link: "/preview/dashboards/invoice/orgs",
          },
        ],
      },
      {
        name: "Virtual Account",
        user_allowed: ["admin", "support", "finops", "compliance"],
        icon: SwitchAccountTwoToneIcon,
        link: "/preview/dashboards/virtual-accounts",
      },
      {
        name: "Payins",
        user_allowed: ["admin", "support", "finops", "compliance", "sales"],
        icon: TollTwoToneIcon,
        link: "/preview/dashboards/all-payins",
      },
      {
        name: "Payouts",
        user_allowed: ["admin", "support", "finops", "compliance", "sales"],
        link: "/preview/dashboards/all-payouts",
        icon: PaymentsTwoToneIcon,
      },
      {
        name: "Transfers",
        user_allowed: ["admin", "finance", "finops"],
        icon: TrendingFlatIcon,
        link: "/preview/dashboards/transfers",
      },
      {
        name: "Checkout",
        user_allowed: ["admin", "support", "finops", "compliance"],
        icon: ConfirmationNumberTwoToneIcon,
        link: "/preview/dashboards/all-checkouts",
      },
      {
        name: "Adjustment",
        user_allowed: ["admin", "compliance", "finance", "finops"],
        icon: MonetizationOnTwoToneIcon,
        link: "/preview/dashboards/all-adjustments",
      },
      {
        name: "Fees Adjustment",
        user_allowed: ["admin", "compliance", "finance", "finops"],
        icon: MonetizationOnTwoToneIcon,
        link: "/preview/dashboards/all-fees-adjustments",
      },
      {
        name: "Customer",
        user_allowed: ["admin", "compliance", "finance", "support"],
        icon: AccountCircleTwoToneIcon,
        link: "/preview/dashboards/all-customers",
      },
      {
        name: "Wallet",
        user_allowed: ["admin", "compliance", "finance", "support"],
        icon: AccountBalanceWalletTwoToneIcon,
        link: "/preview/dashboards/all-wallets",
      },
      {
        name: "Wallet Fund Transfer",
        user_allowed: ["admin", "compliance", "finance", "finops"],
        icon: ShareTwoToneIcon,
        items: [
          {
            name: "New WFT",
            link: "/preview/dashboards/new-wallet-fund-transfer",
          },
          {
            name: "All WFT",
            link: "/preview/dashboards/all-wallet-fund-transfers",
          },
          // {
          //   name: "Organization Specific",
          //   link: "/preview/dashboards/wallet/orgs",
          // },
          {
            name: "Organization Specific",
            link: "/preview/dashboards/wallet-fund-transfer/orgs",
          },
        ],
      },
      {
        name: "Reconciliation",
        user_allowed: ["admin", "support", "finops", "finance"],
        icon: ReceiptLong,
        link: "/preview/dashboards/reconciliation-job",
      },
      {
        name: "Settlements",
        user_allowed: ["admin", "support", "finops", "finance"],
        icon: AutorenewTwoToneIcon,
        items: [
          {
            name: "All Settlements",
            link: "/preview/dashboards/all-settlements",
          },
          {
            name: "All Organizations",
            link: "/preview/dashboards/settlement/orgs",
          },
          {
            name: "Settlement Summary",
            link: "/preview/dashboards/settlement-payout-details",
          },
        ],
      },
      {
        name: "Transactions",
        user_allowed: ["admin", "compliance", "sales", "finance", "support"],
        link: "/preview/dashboards/transactions",
        icon: PaymentTwoToneIcon,
      },
      {
        name: "Stable Coin",
        user_allowed: ["admin", "finance", "support", "compliance"],
        icon: MonetizationOnTwoToneIcon,
        items: [
          {
            name: "Stable Transactions",
            link: "/preview/dashboards/stables-transaction",
          },
          {
            name: "Provider",
            link: "/preview/dashboards/provider",
          },
        ],
      },
      {
        name: "Audit Trail",
        user_allowed: ["admin", "compliance", "support"],
        icon: TimelineTwoTone,
        items: [
          {
            name: "All Organizations",
            link: "/preview/dashboards/audit-trail/orgs",
          },
          {
            name: "All Partners",
            link: "/preview/dashboards/audit-trail/partners",
          },
          {
            name: "Backoffice",
            link: "/preview/dashboards/bo-audit-trail",
          },
        ],
      },
      {
        name: "Org Balance at a Glance",
        user_allowed: ["admin", "finance", "support"],
        icon: RequestQuoteTwoToneIcon,
        link: "/preview/dashboards/org-balance-at-glance",
      },
      {
        name: "Org Txns at a Glance",
        user_allowed: [
          "admin",
          "support",
          "finops",
          "compliance",
          "sales",
          "finance",
        ],
        icon: CorporateFareTwoToneIcon,
        link: "/preview/dashboards/org-at-glance/orgs",
      },
      {
        name: "Org Control at a Glance",
        user_allowed: ["admin", "compliance", "support"],
        icon: TuneTwoTone,
        link: "/preview/dashboards/org-control-at-glance/orgs",
      },
      {
        name: "Limit at a Glance",
        user_allowed: ["admin", "compliance"],
        icon: TuneTwoTone,
        items: [
          {
            link: "/preview/dashboards/org-limit-at-glance/platform",
            name: "Platform Level",
          },
          {
            link: "/preview/dashboards/org-limit-at-glance/orgs",
            name: "Org Level",
          },
        ],
      },
      {
        name: "Partner Config",
        user_allowed: ["admin", "compliance", "sales", "support"],
        icon: ManageAccountsTwoTone,
        link: "/preview/dashboards/partner-config/all-partners",
      },
      {
        name: "Broadcast Email",
        user_allowed: ["admin", "support"],
        icon: CampaignTwoTone,
        link: "/preview/dashboards/broadcast-email",
      },
      {
        name: "Transaction Lookup",
        user_allowed: ["admin", "support", "finops", "compliance", "finance"],
        icon: ManageSearchTwoTone,
        items: [
          {
            name: "Transaction Lookup",
            link: "/preview/dashboards/transaction-lookup",
          },
          {
            name: "Disputed Transactions",
            link: "/preview/dashboards/disputed-transactions",
          },
        ],
      },
      {
        name: "Regulatory Reporting",
        user_allowed: ["admin"],
        icon: AssessmentIcon,
        // link: "/preview/dashboards/regulatory-reporting",
        items: [
          {
            name: "IFTI Reporting",
            items: [
              {
                name: "IFTI Incoming",
                link: "/preview/dashboards/regulatory-reporting/ifti-incoming",
              },
              {
                name: "IFTI Outgoing",
                link: "/preview/dashboards/regulatory-reporting/ifti-outgoing",
              },
            ],
          },
          {
            name: "MAS Reporting",
            link: "/preview/dashboards/regulatory-reporting/mas-reporting",
          },
        ],
      },
      {
        name: "Job Scheduler",
        user_allowed: ["admin"],
        icon: TimelapseTwoTone,
        items: [
          {
            name: "All Scheduler Repositories",
            link: "/preview/dashboards/all-scheduler-repositories",
          },
          {
            name: "Create Job Scheduler",
            link: "/preview/dashboards/create-job-scheduler",
          },
        ],
      },
    ],
  },
];

export default menuItems;
