import { Redirect, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "src/hooks/useAuth";
import { accessToLink } from "src/utils/FinmoNetworkUtils";

const Authenticated = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  const user_type = user?.user_type?.toLowerCase(); 
  const location = useLocation();
  const current_path = location.pathname;

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  const hasAccess = accessToLink(current_path, user_type);

  if (!hasAccess) {
    return <Redirect to="/preview/dashboards/analytics" />;
  }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node,
};

export default Authenticated;
